<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <div class="float-right" style="margin-top: 5px;">
                    <b-button-group>
                        <b-dropdown right no-caret>
                            <template slot="button-content">
                                <i class="fa fa-wrench" aria-hidden="true" style="margin-right: 4px;"></i>
                                {{ activeLanguage.settings }}
                            </template>
                            <b-dropdown-item to="/invoices/generate/cib">
                                {{ activeLanguage.cib }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-button-group>
                </div>
                <h1>
                    {{ activeLanguage.title }}
                </h1>
            </div>
            <div class="table-bar">
                <div class="download_container">
                    <div class="btn-group">
                        <template v-if="disableProcessOrdersBtn">
                            <button
                                :disabled="disableProcessOrdersBtn"
                                class="btn"
                            >
                                {{ activeLanguage.processBtn }}
                            </button>
                        </template>
                        <template v-else>
                            <button
                                @click.prevent="processOrders"
                                :disabled="disableProcessOrdersBtn"
                                class="btn"
                            >
                                {{ activeLanguage.processBtn }}
                            </button>
                        </template>

                    </div>
                </div>
            </div>
            <InvoiceClientsTable
                ref="invTable"
                :activeLanguage="activeLanguage"
                :clients="clients"
                @clientsIsSelected="onClientSelect"
                @individualBtn="getClientOrders"
            />
        </div>
    </div>
</template>

<script>
    import InvoiceClientsTable from '../../components/invoiceGenerationView/InvoiceClientsTable'

    import {mapGetters} from 'vuex'

	export default {
		name: "InvoicesGeneration",
        data() {
			return {
                clients: [],
				clientIds: [],
				disableProcessOrdersBtn: true
            }
        },
        mounted() {
            this.getClients()
        },
        methods: {
	        onClientSelect(ids) {
		        this.disableProcessOrdersBtn = !ids.length

	        	this.clientIds = ids
            },
            getClients() {
	            newbillinkAxios
		            .get('/client-invoice/list/clients')
		            .then(({data}) => {
			           this.clients = data
		            })
		            .catch(err => console.error(err))
            },
            processOrders() {
	            let params = { clients: this.clientIds }
	            newbillinkAxios
		            .post('/client-invoice/process/orders', params)
		            .then(({data}) => {
                        this.getClients()
                        })
		            .catch(err => console.error(err))

                    let syncParams = {
                            type: "run_invoices",
                            clients: this.clientIds
                        }
                        billinkAxios
                            .post('/app/employeelog', syncParams)
                            .then()
                            .catch(err => console.error(err))
            },
	        getClientOrders(client) {
		        let params = { clients: [client.client_id] }
		        newbillinkAxios
			        .get('/client-invoice/list/orders', { params })
			        .then(({data}) => {
				        client.allOrders = data
                        client.orders = client.allOrders.slice(0, 20)
				        this.$refs.invTable.$forceUpdate()
			        })
			        .catch(err => console.error(err))
	        },
        },
        components: {
	        InvoiceClientsTable
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getInvoiceGenerationLanguage'
			})
		},
	}
</script>

<style scoped lang="scss">
    .download_container {
        margin-bottom: 30px;
    }
    .btn[disabled] {
        pointer-events: none;
        &:hover {
            background-color: #4db5af;
        }
    }
</style>
<style scoped>
    >>> .b-dropdown .btn.btn-secondary {
        padding: 0.65rem 2rem 0.5rem 2rem;
        border-radius: .25rem !important;
        background: #4db5af !important;
        color: #ffffff !important;
        border-color: #4db5af !important;
    }
    >>> .dropdown-toggle:active {
        border-color: #4db5af !important;
    }
    >>> .dropdown-menu {
        padding: 0;
    }
    >>> .dropdown-menu .dropdown-item:active a,
    >>> .dropdown-menu .dropdown-item:focus a,
    >>> .dropdown-menu .dropdown-item:hover a {
        background: #4db5af !important;
        color: #ffffff !important;
    }
    >>> .dropdown-menu .dropdown-item:active,
    >>> .dropdown-menu .dropdown-item:focus,
    >>> .dropdown-menu .dropdown-item:hover {
        background: #4db5af !important;
        color: #ffffff !important;
    }
</style>