<template>
    <table class="invoices-table table">
        <thead>
        <tr>
            <th>
                <label class="custom-control custom-checkbox">
                    <input v-model="checkAll" type="checkbox" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                </label>
            </th>
            <th>
                {{ activeLanguage.username}}
            </th>
            <th>
                {{ activeLanguage.orderQuantity }}
            </th>
            <th>
                {{ activeLanguage.totalSum }}
            </th>
            <th>
                {{ activeLanguage.records }}
            </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="client in clients">
            <tr>
                <td @click="checkClient(client)">
                    <label class="custom-control custom-checkbox"  style="pointer-events: none">
                        <input type="checkbox" v-model="client.checked" class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                    </label>
                </td>
                <td @click="checkClient(client)">
                    {{ client.username }}
                </td>
                <td @click="checkClient(client)">
                    {{ client.orders_qty }}
                </td>
                <td @click="checkClient(client)">
                    <template v-if="client.total_sum">
                        <span>€</span>
                        {{ client.total_sum | numberFormat(2)}}
                    </template>
                </td>
                <td>
                    <a href="" @click.prevent="onIndividualClientBtn(client)" class="btn">Individual records</a>
                </td>
            </tr>
            <tr v-if="client.showOrders" class="no-hover">
                <td colspan="5" style="padding: 0.75rem 0 !important;">
                    <div class="invoices-ger-row">
                    <table class="invoices-table table invoices-table--innerTable">
                        <thead>
                        <tr>
                            <th></th>
                            <th>{{ activeLanguage.inv }}</th>
                            <th>{{ activeLanguage.date }}</th>
                            <th>{{ activeLanguage.step }}</th>
                            <th>{{ activeLanguage.amount }}</th>
                            <th>{{ activeLanguage.paid }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tfoot v-if="client.orders && !(client.orders.length === client.allOrders.length)">
                            <tr>
                                <td colspan="7">
                                    <a href="" @click.prevent="showAllClientOrders(client)" class="btn">show all</a>
                                </td>
                            </tr>
                        </tfoot>
                        <tbody>
                        <tr v-for="order in client.orders" @click.prevent="goToOrderPage(order)">
                            <td></td>
                            <td>
                                {{ order.invoice }}
                            </td>
                            <td>
                                {{ order.order_date }}
                            </td>
                            <td>
                                {{ order.step }}
                            </td>
                            <td>
                                <span>€</span>
                                {{ order.amount | numberFormat(2)}}
                            </td>
                            <td>
                                <span>€</span>
                                {{ order.paid | numberFormat(2) }}
                            </td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
	export default {
		name: "InvoiceClientsTable",
		props:{
			activeLanguage: {
				type: Object,
				required: true
			},
			clients: {
				type: Array,
				required: true
			}
		},
        data() {
			return {
				checkAll: false,
            }
        },
        methods: {
	        checkClient(client) {
	        	client.checked = !client.checked
                this.$forceUpdate()
                this.clientIsChecked()
            },
	        clientIsChecked() {
		        let ids = []

		        this.clients.forEach(client => {
			        if (client.checked) { ids.push(client.client_id) }
		        })

		        this.$emit('clientsIsSelected', ids)
	        },
	        uncheckAll() {
	        	this.checkAll = false
		        this.clients.forEach(client => client.checked = false)
            },
	        goToOrderPage({invoice}) {
		        window.open(`/order/${invoice}`, '_blank')
            },
	        onIndividualClientBtn(client) {
	        	client.showOrders = !client.showOrders
		        if (client.showOrders) { this.$emit('individualBtn', client) }
		        this.$forceUpdate()
            },
	        showAllClientOrders(client) {
		        client.orders = client.allOrders
		        this.$forceUpdate()
            },
        },
		watch: {
			checkAll: function (val) {
				let ids = []

				this.clients.forEach(client => {
					client.checked = val
					if (val) { ids.push(client.client_id) }
				})

				this.$emit('clientsIsSelected', ids)
				this.$forceUpdate()
			}
		},
	}
</script>

<style scoped lang="scss">
    tr td {
        cursor: pointer;
        &:nth-last-of-type(1) {
            cursor: auto;
        }
        &:hover {
            background-color: initial;
        }
    }
    .invoices-table td:last-child {
        padding: .75rem;
    }
    .no-hover:hover > td{
        background-color: #fff;
    }
    .invoices-ger-row {
        width: 90%;
        margin: 0 auto;
        table {
            margin-bottom: 0;
        }
        tfoot, thead {
            &:hover {
                th,td {
                    background: #032438;
                }
            }
        }
    }
</style>